import React, { useState } from 'react';
import NextNavLink from '../../../util/NextNavLink';

export const CareerCTA = ({ meta, content }) => {
    let background = content?.styleOptions?.backgroundImage?.mediaItemUrl;
    let fluid = content?.styleOptions?.styleFluidWidth;
    let light_dark = content?.styleOptions?.lightdark;
    let heading = content?.heading;
    let description = content?.description;
    let cta = content?.cta;
    
    return (
        <div className={`overflow-hidden  relative  wow fadeInUp`}>
            <div
                className={`bg-cover py-2 ${!fluid ? 'container' : ''}`}
                style={
                    background && fluid
                        ? { backgroundImage: `url(${background})` }
                        : {}
                }
            >
                <div
                    className={`lg:flex items-center justify-between  bg-cover md:py-36 py-10 lg:text-left text-center ${
                        !fluid ? 'lg:px-24 px-8' : 'container'
                    }`}
                    style={
                        background && !fluid
                            ? {
                                  backgroundImage: `url(${background})`
                              }
                            : {}
                    }
                >
                    <div className='md:ml-16'>
                        {heading && (
                            <h3
                            className="lg:mb-6 mb-8 wow fadeInUp text-white"

                            >
                                {heading}
                            </h3>
                        )}
                        {description && (
                            <h6
                            className="xsm:mb-0 mb-8 wow fadeInUp text-white"
                            >
                                {description}
                            </h6>
                        )}
                    </div>
                    {cta && (
                        <NextNavLink href={cta.url} target={cta.target}>
                            <a
                                className="btn md:btn--lg wow fadeInUp bg-white text-black"
                            >
                                {cta.title}
                            </a>
                        </NextNavLink>
                    )}
                </div>
            </div>
        </div>
    );
};
export default CareerCTA;
