import { useEffect, useState } from 'react';
import NextNavLink from './NextNavLink';

const ExpandableMenu = ({ menuItemsCta, social, getAbsoluteUrl }) => {
  // State to manage the currently expanded menu
  const [expandedMenu, setExpandedMenu] = useState(null);

  // Toggle function for expanding/collapsing menus
  const toggleMenu = (key) => {
    setExpandedMenu((prevKey) => (prevKey === key ? null : key)); // Close if already open, otherwise set as expanded
  };

  //console.log(menuItemsCta);
  return (
    <div>
      {/* Main Menu */}
      {menuItemsCta.map((parent, i) => (
        <div key={i} className="mb-4">
          {/* Toggle Main Menu */}
          <button
            onClick={() => toggleMenu(`main-${i}`)}
            href={parent.url}
            className="w-full text-left uppercase tracking-wider text-[#967EFF] text-[14px] px-4  bg-gray-700 text-white"
            style={{ letterSpacing: '2.5px', fontWeight: 500 }}
          >
            <a href={parent.url}>{parent.title}</a>
            {parent.children.length > 0 && (
              <i
                className={`en-caret-down opacity-30 inline-block transform text-12 px-2 ${
                  expandedMenu === `main-${i}` ? 'rotate-180' : ''
                }`}
              ></i>
            )}
          </button>

          {/* Sub-Menu */}
          {expandedMenu === `main-${i}` && parent.children.length > 0 && (
            <div className="pl-4 mt-1">
              {parent.children.map((sub, index) => (
                <div className={`flex flex-col w-full space-y-1`} key={index}>
                  {/* Sub-Menu Title */}
                  {!sub.title.includes('@!!') && (
                    <div>
                      <button className="text-white opacity-70 hover:opacity-100">
                        {sub.title.includes('@') && sub.children.length > 0
                          ? sub.children[0].title
                          : sub.title}
                      </button>
                    </div>
                  )}

                  {/* Child Menu (always open) */}
                  {sub.children && (
                    <ul className="pl-4">
                      {sub.children.map((el, mi) => (
                        <li key={mi} className="relative mb-2">
                          <div className="flex items-start space-x-2">
                            {/* Icon (conditionally rendered if available) */}
                            {el.acf_menu_additional_fields?.icon && (
                              <div
                                className="flex items-start text-purple"
                                style={{
                                  marginRight: '0.2rem', // Space between icon and title
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: el.acf_menu_additional_fields?.icon,
                                }}
                              />
                            )}

                            {/* Menu Title and Description */}
                            <div>
                              {/* Menu Title */}
                              <NextNavLink
                                href={el.path || el.url}
                                target={el.target}
                              >
                                <a
                                  onClick={() => setShowMenu(false)}
                                  className="text-white opacity-70 hover:opacity-100"
                                >
                                  {el.title}
                                </a>
                              </NextNavLink>

                              {/* Description (conditionally rendered if available) */}
                              {el.acf_menu_additional_fields?.summary && (
                                <div className="text-white opacity-50 text-xs mt-1">
                                  {el.acf_menu_additional_fields.summary}
                                </div>
                              )}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ExpandableMenu;
