import React, { useEffect, useState, useContext } from 'react';

import { Navigation } from './Navigation';
import FooterCTA from './FooterCTA';
import CareerCTA from './CareerCTA';

export default function Footer(props) {
    const {
        menus,
        options,
        defaults,
        optionHideCta = true,
        displayCareerCta = true,
        acf_post_event,
        acf_page_layout
    } = props;

    let hideMenu = 0;
    try {
        if(acf_page_layout['pageLayout'].includes('hidefooter')){
            hideMenu=1;
        }
    } catch (error) {

    }

    let legalMenu = [],
        languageMenu = [],
        menuItems = [];

    if (menus['legal-menu']) {
        legalMenu = menus['legal-menu'].menuItems;
    }
    if (menus['language-menu']) {
        languageMenu = menus['language-menu'].menuItems;
    }
    if (menus['main-menu']) {
        menuItems = menus['main-menu'].menuItems;
    }
    // console.log('Footer',defaults?.careerCtaModule);
    // console.log('Footer',displayCareerCta);
    return (
        <>
            {optionHideCta !== true && (
                <FooterCTA
                    content={defaults?.footerCtaModule?.acf_cta_module}
                />
            )}
            {displayCareerCta === true && (
                <CareerCTA
                    content={defaults?.careerCtaModule?.acf_career_cta_module}
                />
            )}
            <Navigation
                legalMenu={legalMenu}
                language={languageMenu}
                menuItems={menuItems}
                social={options.socialMediaLinks}
                options={options.mainMenuOptions.footer}
                hideMenu={hideMenu}
            />
        </>
    );
}
