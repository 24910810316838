import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { getAbsoluteUrl } from '../../lib/helpers';

/*
 * This is a utility class used to inject active class into a Bootstrap
 * nav link for initial prototype: may be reusable elsewhere.
 */
const NextNavLink = ({ href, target, children, passHref, activeClassName }) => {
    useEffect(() => {
        replaceHost();
    });
    replaceHost();
    function replaceHost() {
        if (typeof window !== 'undefined') {
            const hostname = window.location.hostname;
            if (hostname === 'localhost') {
                href = href.replace(
                    'https://enfusion-api.webworkinprogress.com',
                    'http://localhost:3000'
                );
                // href = href.replace(
                //     'https://cms.enfusion.com',
                //     'http://localhost:3000'
                // );
                // console.warn(href)
                // href = href.replace(
                //     'https://enfusion.com',
                //     'http://localhost:3000'
                // );
            }
        }
    }

    const router = useRouter();

    let className = children.props.className || '';

    if (href !== '#' && router?.asPath.split('#')[0] === href?.split('#')[0]) {
        className = `${className} ${activeClassName ? activeClassName : ''}`;
    }
    
    const voidLink = href && href.startsWith('#');
    // if(!href)
    //     return <></>;
    return voidLink ? (
        React.cloneElement(children, {
            className,
            href: getAbsoluteUrl(href),
            target
        })
    ) : (
        <Link href={getAbsoluteUrl(href)} passHref={passHref} legacyBehavior>
            {React.cloneElement(children, { className, target })}
        </Link>
    );
};

export default NextNavLink;
