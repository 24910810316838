export const getAbsoluteUrl = function (url) {
    let absoluteUrl = url;
    if (!url) return url;
    if (typeof url !== 'string') return url;
    if (url.startsWith('/')) return url;
    if (url.startsWith('#')) return url;

    try {
        const blob = new URL(url);
        const isFile = blob.pathname.split('/').pop().indexOf('.') > 0;
        const invalidHosts = [
            'cms.enfusion.com',
            'enfusion-api.webworkinprogress.com',
            'www.enfusion.com',
            'enfusioncms.wpengine.com',
            'enfusioncorp.wpengine.com'
        ];
        if (isFile) return url;
        if (invalidHosts.includes(blob.hostname)) {
            const match = invalidHosts.indexOf(blob.hostname);
            const newUrl = url.replace(
                invalidHosts[match],
                (
                    (process.env.NEXT_PUBLIC_SITE_DOMAIN)
                    ?.replace("https://","")
                    ?.replace("https//","")
                    ?.replace("http://","")
                    ?.replace("http//","")
                ) || 'enfusion.com'
            );
            return newUrl;
        }

        return blob.toString();
    } catch (err) {
        console.warn({ url, err });
    }
    return absoluteUrl;
};
