import { Transition } from '@headlessui/react';
import React, { useState } from 'react';
import { useAppContext } from '../../../util/context';

import NextNavLink from '../../util/NextNavLink';
export const Desclaimer = ({}) => {
    const context = useAppContext();

    return (
        <Transition
            show={context?.disclaimer}
            className="fixed top-0 left-0 w-full h-screen z-[9999] bg-purple-midnight bg-opacity-60"
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <div className="bg-white md:max-w-2xl max-w-[95vw] h-ato max-h-[95vh] overflow-auto fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-10 w-full">
                <h5 className="mb-4 md:mb-6">Do you wish to continue?</h5>
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        context.toggleDisclaimer(false);
                        context.toggleDisclaimerContent('');
                        context.toggleDisclaimerLink('');
                    }}
                    className="absolute flex items-center font-medium lead text-purple-midnight top-4 right-4"
                >
                    <span className="min-w-[42px]">Close</span>
                    <span
                        className={`icon-expaded relative h-6 w-6 flex-col justify-between items-center flex ml-3`}
                    >
                        <span
                            className={`absolute inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                        ></span>
                        <span
                            className={`relative top-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                        ></span>
                        <span
                            className={`inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                        ></span>
                        <span
                            className={`relative bottom-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                        ></span>
                        <span
                            className={`absolute bottom-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                        ></span>
                    </span>
                </a>
                <div
                    className="mb-4 italic lead opacity-70 md:mb-6"
                    dangerouslySetInnerHTML={{
                        __html: context?.disclaimerContent
                    }}
                />
                <div className="flex space-x-5">
                    <a
                        onClick={(e) => {
                            e.preventDefault();
                            context.toggleDisclaimer(false);
                            context.toggleDisclaimerContent('');
                            context.toggleDisclaimerLink('');
                            window.open(context?.disclaimerLink, '_blank');
                        }}
                        href=""
                        className="btn btn--midnight min-w-[100px]"
                    >
                        Yes
                    </a>
                    <a
                        href=""
                        onClick={(e) => {
                            e.preventDefault();
                            context.toggleDisclaimer(false);
                            context.toggleDisclaimerContent('');
                            context.toggleDisclaimerLink('');
                        }}
                        className="btn btn--o-midnight min-w-[100px]"
                    >
                        No
                    </a>
                </div>
            </div>
        </Transition>
    );
};
export default Desclaimer;
