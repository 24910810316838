import React, { useEffect, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';

import NextNavLink from '../../../util/NextNavLink';
import { Dropdown, Language } from '../Dropdown';

import { useDebounce, useClickOutside } from '../../../../util/hooks';
import { gtag } from '../../../../util/googleTagManager';
import { getAbsoluteUrl } from '../../../../lib/helpers';
import { useBackgroundChecker } from '@util/useBackgroundChecker';
import ExpandableMenu from '@cutil/ExpandableMenu';
import tinycolor from 'tinycolor2';
import { useRouter } from 'next/router';

function isColorDarkOrLight(color) {
    let r, g, b;
    console.log(color)
    if (color.match(/^#([A-Fa-f0-9]{3}){1,2}$/)) {
        let c = color.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        r = (c >> 16) & 255;
        g = (c >> 8) & 255;
        b = c & 255;
    } else if (color.startsWith('rgb')) {
        const values = color.match(/\d+/g);
        r = parseInt(values[0]);
        g = parseInt(values[1]);
        b = parseInt(values[2]);
    } else {
        return null;
    }

    let tcolor = tinycolor({ r: r, g: g, b: b });
    console.log(tcolor.getLuminance())
    // Using the HSP value, determine whether the color is light or dark
    return tcolor.getLuminance() > 0.075 ? 'light' : 'dark';
    //   const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    //   return luminance > 0.5 ? 'light' : 'dark';
}

function getAverageColorOfImage(image, callback) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = image.width;
    canvas.height = image.height;

    try {
        context.drawImage(image, 0, 0, canvas.width, canvas.height);

        const data = context.getImageData(0, 0, canvas.width, canvas.height).data;
        let r = 0, g = 0, b = 0;

        for (let i = 0; i < data.length; i += 4) {
            r += data[i];
            g += data[i + 1];
            b += data[i + 2];
        }

        r = Math.floor(r / (data.length / 4));
        g = Math.floor(g / (data.length / 4));
        b = Math.floor(b / (data.length / 4));

        const color = tinycolor({ r: r, g: g, b: b });
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        //console.log(color.toRgbString(), color.getLuminance())
        callback(color.getLuminance() > 0.075 ? 'light' : 'dark');
    } catch (error) {
        console.error('CORS prevents image processing.', error);
        callback('default');  // Fallback in case of CORS issue
    }
}

function processImage(imageSrc, setBgType) {
    const image = new Image();
    image.crossOrigin = "Anonymous"; // Try to bypass CORS issue for external images
    image.src = imageSrc;

    image.onload = () => {
        try {
            // Create a canvas to draw the image and extract pixel data
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            // Set the canvas width and height to the image's dimensions
            canvas.width = image.width;
            canvas.height = image.height;

            // Draw the image onto the canvas
            context.drawImage(image, 0, 0);

            // Set the coordinates to extract the top 100px region from the left to 50% of the image width
            const leftX = 0;  // Left-most side
            const middleX = Math.floor(image.width / 2);  // 50% of image width
            const topY = 0;   // Starting from the top
            const height = 100; // 100px from the top

            // Get the pixel data from the top 100px region from the left to 50% width
            const imageData = context.getImageData(leftX, topY, middleX, height);
            const pixels = imageData.data;

            // Optionally, calculate the average color of the extracted region
            let r = 0, g = 0, b = 0;
            const pixelCount = pixels.length / 4; // Each pixel has 4 values (RGBA)

            for (let i = 0; i < pixels.length; i += 4) {
                r += pixels[i];     // Red
                g += pixels[i + 1]; // Green
                b += pixels[i + 2]; // Blue
            }

            // Compute the average color
            r = Math.floor(r / pixelCount);
            g = Math.floor(g / pixelCount);
            b = Math.floor(b / pixelCount);

            const averageColor = `rgb(${r}, ${g}, ${b})`;
            // console.log(`Average color: ${averageColor}`);

            // Handle this color as needed
            const color = tinycolor(averageColor);
            //console.log('luminance',color.getLuminance())
            setBgType(color.getLuminance() > 0.075 ? 'light' : 'dark'); // Set the background type or color
        } catch (error) {
            console.error('Unable to process image due to CORS policy or other error.', error);
            setBgType('dark');  // Handle accordingly, e.g., fallback color or type
        }
    };

    image.onerror = () => {
        console.error('Failed to load image.');
        setBgType('dark'); // Handle loading error
    };

}

/* An example Tailwind UI menu supporting sub-items as dropdowns */
function Navigation({
    menuItems,
    menuItemsCta,
    language,
    social,
    sticky,
    options,
    variant,
    layoutType,
    footer,
    headerTheme = '',
    hideMenu
}) {

    //For mobile menu
    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef(null);
    const [CTAOpen, setCTAOpen] = useState(false);
    //check the bg type for dark and light menu

    const [showSearchResults, setShowSearchResults] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    // State to track whether the user has scrolled down
    const [isScrolled, setIsScrolled] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const debouncedQuery = useDebounce(searchQuery, 500);
    const searchRef = useRef();
    const [megaMenuBgColor, setMegaMenuBgColor] = useState('bg-[#ffffff]');
    const [parentMenuBackColor, setParentMenuBackColor] = useState(megaMenuBgColor === 'bg-[#1E1A34]' ? 'bg-[#2A1C41]' : 'bg-white');
    const [submenuColor, setSubmenuColor] = useState(megaMenuBgColor === 'bg-[#1E1A34]' ? 'text-white' : 'text-purple-midnight');
    const [bgColor, setBgColor] = useState();
    const [path, setPath] = useState('');

    const [bgType, setBgType] = useState('');

    const [isDark, setIsDark] = useState(false);
    const [menuTextColor, setMenuTextColor] = useState('text-white');
    const [textColor, setTextColor] = useState('text-black');
    const [caret, setCaret] = useState('https://enfusioncorp.wpengine.com/wp-content/uploads/2024/10/unnamed-file.png');
    const { asPath } = useRouter();
    // useClickOutside(ref, () => setShowMenu(false));
    useClickOutside(searchRef, () => setShowSearchResults(false));
    const [isHovered, setIsHovered] = useState(false);

    ///***mega menu working start***/
    const hideTimeout = useRef(null);
    const [prevMenuIndex, setPrevMenuIndex] = useState(null);
    const [activeMenu, setActiveMenu] = useState(-1);
    const [currentMegaMenu, setCurrentMegaMenu] = useState();
    const megaMenuRef = useRef(null);
    const mainMenuRef = useRef(null);
    const isHoveringMegaMenuRef = useRef(false); // Ref for tracking mega menu hover state
    const isHoveringMainMenuRef = useRef(false); // Ref for tracking main menu hover state
    const preserverHoveringState = useRef(false); // Ref for tracking main menu hover state
    ///***mega menu working end***/
    //console.log(menuItemsCta);
    const gridTemplate = (columnCount) => {
        if (columnCount === 1) return 'grid-cols-[3fr,4fr]';
        if (columnCount === 2) return 'grid-cols-[3fr,3fr,4fr]';
        if (columnCount === 3) return 'grid-cols-[4fr,4fr,4fr]';
        return 'grid-cols-[3fr,4fr]'; // Default case
    };



    useEffect(() => {
        // Check if the Meta Pixel script is already added
        if (!window.fbq) {
            // Dynamically inject the script
            (function (f, b, e, v, n, t, s) {
                if (f.fbq) return;
                n = f.fbq = function () {
                    n.callMethod
                        ? n.callMethod.apply(n, arguments)
                        : n.queue.push(arguments);
                };
                if (!f._fbq) f._fbq = n;
                n.push = n;
                n.loaded = !0;
                n.version = '2.0';
                n.queue = [];
                t = b.createElement(e);
                t.async = !0;
                t.src = v;
                s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s);
            })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

            // Initialize the pixel with your ID
            window.fbq('init', '1999724390490710');
        }

        // Track page views
        window.fbq('track', 'PageView');
    }, []); // This runs only once when the component mounts


    useEffect(() => {
        // Create the main Facebook Pixel script
        const script = document.createElement('script');
        script.setAttribute('nonce', 'EEaEJd4r');
        script.async = true;
        script.src = 'https://connect.facebook.net/en_US/fbevents.js';

        // Set up the fbq function and initialize tracking after script loads
        script.onload = () => {
            window.fbq = function () {
                window.fbq.callMethod ? window.fbq.callMethod(...arguments) : window.fbq.queue.push(arguments);
            };
            window.fbq.queue = [];
            window.fbq.version = '2.0';
            window.fbq('init', '1116250213536536');
            window.fbq('track', 'Lead');
        };

        // Append the script to the document head
        document.head.appendChild(script);

        // Add the noscript fallback for users with JavaScript disabled
        const noscript = document.createElement('noscript');
        noscript.innerHTML = `<img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=1116250213536536&ev=PageView&noscript=1"/>`;
        document.head.appendChild(noscript);

        // Cleanup function to remove the elements if the component unmounts
        return () => {
            document.head.removeChild(script);
            document.head.removeChild(noscript);
        };
    }, []);
    useEffect(() => {
        // console.log('path', asPath)
        setActiveMenu(-1);
    }, [path, asPath])

    useEffect(() => {
        setActiveMenu(-1);
        function checkBackground() {
            if (path != asPath) {


                setPath(asPath)
            }
            // console.log('background check')
            // Get the element at the center of the window
            // Check if the ".swiper-slide-active" element is present
            const activeSlide = document.querySelector('main > div > div > div > div.swiper-slide-active');
            const header = document.querySelector('main > header');
            const mainImage = document.querySelector('main > div > div'); // third priority
            let element;

            // If active slide is found, use it, otherwise use the element at the center of the window
            if (activeSlide) {
                // console.log('Using .swiper-slide-active element');
                element = activeSlide.querySelector('div:first-child');;
            } else if (mainImage) {
                element = mainImage;
            } else if (header) {
                element = header;
            } else {
                // console.log('No .swiper-slide-active element found, using element from center of the window');
                element = document.elementFromPoint(window.innerWidth / 2, window.innerHeight / 2);
            }
            // console.log(activeSlide,mainImage)

            //console.log(element)
            // Check if the element is an image tag
            if (element.tagName.toLowerCase() === 'img') {
                const result = processImage(element.src, setBgType); // Process image from img tag
                // setBgType(result);
                //return;
            } else {
                let computedStyle = window.getComputedStyle(element);
                // Check for background image in a div (like swiper-slide)
                const bgImage = computedStyle.backgroundImage;

                if (bgImage && bgImage !== 'none') {
                    const url = bgImage.match(/url\(["']?([^"']*)["']?\)/)[1];

                    const result = processImage(url, setBgType); // Process background image
                    // setBgType(result);
                    //return;
                } else {
                    // If neither, fallback to background color check
                    const bgColor = computedStyle.backgroundColor;
                    // console.log(bgColor,element)

                    if (bgColor && bgColor !== 'rgba(0, 0, 0, 0)') { // Skip transparent
                        const result = isColorDarkOrLight(bgColor);
                        if (result) {
                            setBgType(result);
                            //return;
                        }
                    }
                }
            }
        }

        checkBackground(); // Initial check

        const handleScroll = () => {
            checkBackground();
            if (window.scrollY > 35) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Run checkBackground every 5 seconds
        const intervalId = setInterval(() => {
            checkBackground();
        }, 2000);

        // Cleanup: remove scroll listener and interval when necessary
        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearInterval(intervalId); // Clear interval when component unmounts or necessary
        };
    }, []);

    const closeMenu = () => {
        setShowMenu(false);
    };

    useEffect(async () => {
        if (debouncedQuery && debouncedQuery.length > 2) {
            const resp = await fetch(
                '/api/search?' +
                new URLSearchParams({
                    q: searchQuery
                })
            );
            const json = await resp.json();

            if (json.response !== 'SUCCESS') {
                setSearchResults([]);
                setShowSearchResults(false);

                return;
            }

            setSearchResults(json.results);
            setShowSearchResults(true);
        } else {
            setSearchResults([]);
            setShowSearchResults(false);
        }
    }, [debouncedQuery]);

    function resetSearch() {
        setShowSearchResults(false);
        setSearchQuery('');
    }


    let menuIconColorTheme =
        (sticky && !showMenu) || (!variant && !showMenu)
            ? 'bg-purple-midnight'
            : 'bg-white';

    useEffect(() => {
        // console.log('set bgcolor', bgType)
        setBgColor(sticky ? 'light' : bgType);
    }, [bgType, sticky, asPath]);

    useEffect(() => {
        // console.log('set is dark')
        setIsDark(bgColor == 'dark');
    }, [bgColor]);

    useEffect(() => {
        if (preserverHoveringState.current || sticky) {
            setMenuTextColor('text-purple-midnight');
            setCaret('https://enfusioncorp.wpengine.com/wp-content/uploads/2024/10/unnamed-file.png')
        } else if (isDark && !sticky && !preserverHoveringState.current) {
            setMenuTextColor(isDark ? 'text-white' : 'text-purple-midnight');
            setCaret(isDark ? 'https://enfusioncorp.wpengine.com/wp-content/uploads/2024/10/Arrow.png' : 'https://enfusioncorp.wpengine.com/wp-content/uploads/2024/10/unnamed-file.png')
        } else {
            setMenuTextColor('text-purple-midnight');
            setCaret('https://enfusioncorp.wpengine.com/wp-content/uploads/2024/10/unnamed-file.png');
        }

        // console.log('bg', bgColor, 'is dark', isDark, 'active', preserverHoveringState.current, 'color', menuTextColor, 'sticky', sticky)
    }, [isDark, preserverHoveringState.current, currentMegaMenu?.index, isHoveringMainMenuRef.current, asPath])

    const handleMouseEnter = (index, hasChildren) => {
        // console.log('handleMouseEnter', isHoveringMainMenuRef.current)
        if (hasChildren) {
            setPrevMenuIndex(currentMegaMenu?.index); // Save the previous menu index
            setCurrentMegaMenu({ isOpen: true, index });
            clearTimeout(hideTimeout.current);
        } else {
            if (currentMegaMenu?.isOpen) {
                setCurrentMegaMenu({ isOpen: false, index: currentMegaMenu.index });
            }
        }
    };

    const handleMouseLeaveMainMenu = () => {
        isHoveringMainMenuRef.current = false; // Update the ref when leaving main menu
        hideTimeout.current = setTimeout(() => {
            if (!isHoveringMegaMenuRef.current) {
                // if(currentMegaMenu?.index)
                setCurrentMegaMenu({ isOpen: false, index: currentMegaMenu?.index });
            }
        }, 100);
    };

    const handleMouseEnterMegaMenu = () => {
        isHoveringMegaMenuRef.current = true; // Update the ref when hovering over mega menu
    };

    const handleMouseLeaveMegaMenu = () => {
        isHoveringMegaMenuRef.current = false; // Update the ref when leaving mega menu
        preserverHoveringState.current = false;
        // console.log('handleMouseLeaveMainMenu', isHoveringMegaMenuRef.current)
        hideTimeout.current = setTimeout(() => {
            if (!isHoveringMainMenuRef.current) {
                setCurrentMegaMenu({ isOpen: false, index: currentMegaMenu.index });
                // if(currentMegaMenu?.index){
                // }
            }
        }, 100);
    };

    // New function to handle click events
    const handleMenuClick = (index) => {
        // console.log('close menu')
        if (currentMegaMenu?.index === index && currentMegaMenu?.isOpen) {
            // If the menu is already open, close it
            setCurrentMegaMenu({ isOpen: false, index });
        } else {
            // Otherwise, open the menu
            setCurrentMegaMenu({ isOpen: true, index });
        }
    };

    const megaMenuVisibility = (menuElement, isOpen) => {
        if (menuElement) {
            menuElement.classList.toggle('visible', isOpen);
            menuElement.classList.toggle('opacity-100', isOpen);
            menuElement.classList.toggle('translate-y-0', isOpen);
            menuElement.classList.toggle('invisible', !isOpen);
            menuElement.classList.toggle('opacity-0', !isOpen);
            menuElement.classList.toggle('-translate-y-[250px]', !isOpen);
        }
    };

    useEffect(() => {
        if (currentMegaMenu) {
            const { isOpen, index } = currentMegaMenu;

            // Close the previous menu if there's a previous index and it's different from the current one
            if (prevMenuIndex !== null && prevMenuIndex !== index) {
                const prevMegaMenu = document.getElementById(`mega-menu-${prevMenuIndex}`);
                if (prevMegaMenu) {
                    megaMenuVisibility(prevMegaMenu, false); // Hide the previous menu
                }
            }

            // Open or close the current menu
            const currentMenuElement = document.getElementById(`mega-menu-${index}`);
            if (currentMenuElement) {
                megaMenuVisibility(currentMenuElement, isOpen);
            }

            // Reset active menu if the current menu is closed
            if (!isOpen) {
                setActiveMenu(-1);
            }
        }
    }, [currentMegaMenu]);

    return (
        <>
            <header
                className={`fixed top-0 left-0 w-full pr-[1rem] md:pr-[1rem] lg:pr-[0.2rem] xl:pr-[3rem] 2xl:pr-[12rem] pl-[1rem] md:pl-[1rem] lg:pl-[0.1rem] xl:pl-[2rem] 2xl:pl-[2rem] z-[99] transition-all duration-[400ms] 
    ${isScrolled ? 'bg-white shadow-nav' : 'bg-transparent '}
    ${preserverHoveringState.current ? `${parentMenuBackColor}` : ''}`}
                ref={menuRef}
                style={{
                    transition: 'margin-top 0.4s ease-in-out, border-bottom-left-radius 0.4s ease-in-out',
                }}
            >
                <div className="flex items-center justify-between ">
                    <div
                    //     className={`fixed top-0 left-0 w-full px-6 py-4 z-[99] transition-all duration-400 ${sticky && !showMenu ? 'bg-white bg-opacity-100' : ''
                    // } ${!variant && !showMenu && !sticky ? '' : ''}  ${sticky && !showMenu ? 'shadow-nav' : ''
                    // } ${!showMenu && headerTheme}`}
                    >
                        <NextNavLink href="/">
                            <a
                                className="flex"
                                onClick={(e) => {
                                    setShowMenu(false);
                                }}
                            >
                                <span className="sr-only">Enfusion</span>
                                <img
                                    className="w-100% lg:max-w-[139px] max-w-[116px] z-[99]"
                                    src={
                                        (sticky && !showMenu) ||
                                            (!variant && !showMenu) ||
                                            preserverHoveringState.current
                                            ? '/images/enfusion-logo-dark.svg'
                                            : '/images/enfusion-logo-white.svg'
                                    }
                                    alt="Enfusion"
                                />

                            </a>
                        </NextNavLink>
                    </div>

                    {!hideMenu && (
                        <div className="-mr-1 lg:hidden">
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowMenu(!showMenu);
                                }}
                                className={`flex items-center lead opacity-80 hover:opacity-100 ${(sticky && !showMenu) ||
                                    (!variant && !showMenu)
                                    ? 'text-purple-midnight'
                                    : 'text-white'
                                    }`}
                            >
                                <span className="min-w-[42px]">
                                    {!showMenu ? `Menu` : 'Close'}
                                </span>
                                <span
                                    className={`${showMenu
                                        ? 'icon-expaded'
                                        : 'icon-collapse'
                                        } relative h-6 w-6 flex-col justify-between items-center flex ml-3`}
                                >
                                    <span
                                        className={`absolute inline-block w-1 h-1 rounded-10 ${menuIconColorTheme}`}
                                    />
                                    <span
                                        className={`relative top-0 inline-block w-1 h-1 rounded-10 ${menuIconColorTheme}`}
                                    />
                                    <span
                                        className={`inline-block w-1 h-1 rounded-10 ${menuIconColorTheme}`}
                                    />
                                    <span
                                        className={`relative bottom-0 inline-block w-1 h-1 rounded-10 ${menuIconColorTheme}`}
                                    />
                                    <span
                                        className={`absolute bottom-0 inline-block w-1 h-1 rounded-10 ${menuIconColorTheme}`}
                                    />
                                </span>
                            </a>
                        </div>
                    )}

                    {!hideMenu && (
                        <div className={`hidden lg:flex items-center justify-between flex-grow z-[100]`}>
                            {/* Main Navigation */}
                            <nav className={`relative py-3 ml-[1rem] md:ml-[1rem] lg:ml-[0rem] xl:ml-[2rem] 2xl:ml-[8rem]`} ref={menuRef}>
                                <ul
                                    className="flex items-center justify-center mega-menu-nav"
                                    ref={mainMenuRef}
                                    onMouseEnter={() => {
                                        isHoveringMainMenuRef.current = true;
                                        preserverHoveringState.current = true;
                                    }}
                                    onMouseLeave={() => {
                                        handleMouseLeaveMainMenu();
                                        preserverHoveringState.current = false;
                                    }}
                                >
                                    {menuItemsCta.map((menuItem, index) => (
                                        <li
                                            key={index}
                                            data-index={index}
                                            className="group px-2 !mx-2 lg:!mx-0 pt-4 items-center justify-between custom-li"
                                            onMouseEnter={() => handleMouseEnter(index, menuItem.children && menuItem.children.length > 0)}
                                            onClick={() => handleMenuClick(index)}
                                            onMouseLeave={handleMouseLeaveMainMenu}
                                            style={{
                                                transition: 'background-color 0.4s ease-in-out',
                                            }}
                                        >
                                            <a href={menuItem?.url} >
                                                <button
                                                    className={`pl-1 hover:opacity-50 hover:text-[#967EFF] cursor-default ${menuTextColor}`}
                                                    aria-expanded="false"
                                                    aria-controls={`mega-menu-${index}`}
                                                    style={{
                                                        borderBottomLeftRadius: menuItem.children && menuItem.children.length > 0 ? '20px' : '0',
                                                        transition: 'all 0.4s ease-in-out',
                                                    }}
                                                >
                                                    {menuItem.title}
                                                    {/* Display caret if the menu item has children */}
                                                    {menuItem.children && menuItem.children.length > 0 && (
                                                        <img
                                                            src={caret}
                                                            alt="caret"
                                                            className={`pl-1 inline-block transition-transform duration-500 ease-in-out ${currentMegaMenu?.index === index && currentMegaMenu?.isOpen ? 'rotate-180 ml-1' : 'ml-0'
                                                                }`}
                                                        />
                                                    )}
                                                </button>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </nav>

                            {/* Right Column Navigation */}
                            <div className="flex flex-row items-center justify-start  z-[10] list-none">
                                {options.topCtaButton.title && (
                                    <li key="topCtaButton" className="group  px-2 pt-4 !mx-2 lg:!mx-0 custom-li ">
                                        <NextNavLink href={options?.topCtaButton?.url} target={options?.topCtaButton?.target}>
                                            <a
                                                onClick={() => setShowMenu(false)}
                                                className={`cursor-pointer btn btn--primary text-white`}
                                                style={{
                                                    transition: 'color 0.4s ease-in-out',
                                                }}
                                            >
                                                {options?.topCtaButton?.title}
                                            </a>
                                        </NextNavLink>
                                    </li>
                                )}

                                {options?.rightCtaButton?.title && (
                                    <li key="rightCtaButton" className="group  px-2 pt-4 !mx-2 lg:!mx-0 custom-li">
                                        <NextNavLink href={options?.rightCtaButton?.url} target={options?.rightCtaButton?.target}>
                                            <a
                                                onClick={() => setShowMenu(false)}
                                                className={`flex items-center lead hover:opacity-100 ${menuTextColor}`}
                                                style={{
                                                    transition: 'color 0.4s ease-in-out',
                                                }}
                                            >
                                                {options?.rightCtaButton?.title}
                                            </a>
                                        </NextNavLink>
                                    </li>
                                )}
                                <a href={'/clients/'}>

                                    <li className={`group ${menuTextColor}  px-2 !mx-2 pt-4 lg:!mx-0  custom-li`}>

                                        <button
                                            className="flex items-center lead hover:opacity-100"
                                            aria-expanded="false"
                                            style={{
                                                transition: 'color 0.4s ease-in-out',
                                            }}
                                        >
                                            Log In
                                        </button>

                                    </li>
                                </a>

                            </div>
                        </div>
                    )}

                </div>
                {/*mega menu*/}
                <div>
                    {menuItemsCta.map((menuItem, index) => (

                        menuItem.children.length > 0 && (
                            <div
                                key={`mega-menu-${index}`}
                                id={`mega-menu-${index}`}
                                data-index={index}
                                ref={megaMenuRef} // Attach the ref here
                                onMouseEnter={handleMouseEnterMegaMenu}
                                onMouseLeave={handleMouseLeaveMegaMenu}
                                // fixed top-0 right-0 pt-12 w-full megamenusize transition-opacity opacity-0 invisible group-hover:opacity-100 group-hover:visible ${megaMenuBgColor} translate-x-0 duration-100 ease-in-out z-[40]
                                className={`fixed top-0 pt-20 right-0 px-[1rem] md:px-[1rem] lg:px-[1rem] xl:px-[8rem] 2xl:px-[12rem] w-full megamenusize transition-all opacity-0 invisible ${megaMenuBgColor} -translate-y-[250px] duration-300 ease-in-out z-[40]`}
                                style={{
                                    // minWidth: '1200px', // Ensure the width covers your content
                                    // width: '100%',      // Make sure it stretches across the available width
                                    // maxWidth: '1500px', // Optional: set a maximum width for better control
                                    // transform: 'translateX(0)', // Adjust this if needed
                                    // background: '#F5F6F6',
                                }}
                            >

                                <div className="relative top-0 p-6  pl-12  rounded-xl  w-full">
                                    <div
                                        className="w-10 h-10 transform rotate-45 absolute top-0 z-0 transition-transform duration-500 ease-in-out rounded-sm"
                                    >
                                    </div>
                                    <div className={`relative z-10  rounded-bl-xl`}>
                                        <div className={`grid ${gridTemplate(menuItem.children.length)} gap-12`}>
                                            {menuItem.children.map((submenu, i) => {
                                                // Calculate the middle grid(s)
                                                const isMiddleGrid =
                                                    (menuItem.children.length % 2 === 1 && i === Math.floor(menuItem.children.length / 2)) ||
                                                    (menuItem.children.length % 2 === 0 && (i === Math.floor(menuItem.children.length / 2) || i === Math.floor(menuItem.children.length / 2) - 1));
                                                const last = i == menuItem.children.length - 1 ? 'w-[400px]' : 'w-[300px]';

                                                return (
                                                    <div
                                                        key={i}
                                                        className={`p-4 md:p-6 lg:p-6 ${isMiddleGrid ? 'border-l border-r' : ''}`} style={isMiddleGrid ? { borderLeft: '1px solid #3A2D7133', borderRight: '1px solid #3A2D7133' } : {}}
                                                    >
                                                        {!submenu.title.includes('@') && (
                                                            <p className={`uppercase tracking-wider text-[#967EFF] text-[14px]`} style={{ letterSpacing: '2.5px', fontWeight: 500 }}>
                                                                {submenu.title}
                                                            </p>
                                                        )}

                                                        <ul className="mt-3 text-[15px]">
                                                            {submenu.children.map((item, i) => (
                                                                <li key={i} className="my-5 custom-li">
                                                                    {
                                                                        item.acf_menu_additional_fields?.displayAsBlock ?
                                                                            <div className="flex items-center text-white font-medium bg-purple min-h-32 min-w-80 justify-between p-5 rounded-lg" style={{ borderRadius: '10px;' }}>
                                                                                <div className="text-white">
                                                                                    <div
                                                                                        dangerouslySetInnerHTML={{ __html: item.acf_menu_additional_fields?.summary }}
                                                                                    />
                                                                                </div>
                                                                                <div className="flex items-center space-x-4">
                                                                                    <div
                                                                                        className="text-18"
                                                                                    />
                                                                                    <NextNavLink
                                                                                        href={item?.url}
                                                                                        target={item?.target}
                                                                                    >
                                                                                        <a
                                                                                            onClick={(e) => setShowMenu(false)}
                                                                                            className="cursor-pointer btn btn--primary border border-white rounded-full px-4 py-2 text-15"
                                                                                            style={{ borderWidth: '1px', borderRadius: '20px' }}
                                                                                        >
                                                                                            {item?.title}
                                                                                        </a>
                                                                                    </NextNavLink>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            item.acf_menu_additional_fields?.heading ?
                                                                                <p className={`uppercase tracking-wider text-[#967EFF] text-[14px]`} style={{ letterSpacing: '2.5px', fontWeight: 500 }}>
                                                                                    {item.title}
                                                                                </p>
                                                                                :
                                                                                <a href={item?.url}>
                                                                                    <div className={`relative flex items-center ${submenuColor}`}>
                                                                                        {item.acf_menu_additional_fields?.icon && (
                                                                                            <div
                                                                                                className="absolute left-0 flex items-center"
                                                                                                style={{
                                                                                                    marginRight: '0.2rem', // Space between icon and title
                                                                                                    top: '25%',
                                                                                                    transform: 'translateY(-50%)' // Vertically center the icon with the title
                                                                                                }}
                                                                                                dangerouslySetInnerHTML={{ __html: item.acf_menu_additional_fields?.icon }}
                                                                                            />
                                                                                        )}
                                                                                        <div className="flex flex-col ml-[calc(2rem+1rem)] testing group">
                                                                                            <div
                                                                                                style={{
                                                                                                    letterSpacing: '0.2px',
                                                                                                    fontWeight: 400,
                                                                                                    fontSize: '15px',
                                                                                                    lineHeight: '22px',
                                                                                                    display: 'flex',
                                                                                                    alignItems: 'center',
                                                                                                }}
                                                                                            >

                                                                                                <span
                                                                                                    className="group-hover:text-[#7d5fff]"
                                                                                                    dangerouslySetInnerHTML={{ __html: item?.title }}
                                                                                                />

                                                                                            </div>
                                                                                            <div
                                                                                                style={{
                                                                                                    letterSpacing: '0.25px',
                                                                                                    fontWeight: 400,
                                                                                                    fontSize: '12px',
                                                                                                    lineHeight: '18px',
                                                                                                    marginTop: '0.5em',
                                                                                                }}
                                                                                                dangerouslySetInnerHTML={{ __html: item.acf_menu_additional_fields?.summary }}
                                                                                            />
                                                                                        </div>

                                                                                    </div>
                                                                                </a>
                                                                    }

                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                );
                                            })}
                                        </div>

                                    </div>
                                </div>
                            </div>

                        )
                    ))}
                </div>
            </header>
            <Transition
                show={showMenu}
                className="relative z-[11]"
                enter="duration-200 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div className="fixed top-0 left-0 z-10 flex flex-col justify-between w-screen max-h-screen min-h-screen overflow-auto bg-cover bg-gradient-10">
                    <div className="container">
                        <div className="fixed top-0 left-0 flex items-center justify-between w-full p-6 pt-20 lg:hidden bg-gradient-to-b from-black via-black to-black-0 z-[2]"></div>
                        <div className="grid flex-row flex-wrap w-full gap-10 pb-32 mb-10 md:gap-8 lg:justify-center sm:grid-cols-2 lg:grid-cols-4 lg:pt-40 pt-28 lg:pb-0">

                            <ExpandableMenu menuItemsCta={menuItemsCta} />
                        </div>
                    </div>
                    {options.ctaHeading && (
                        <Banner
                            heading={options.ctaHeading}
                            cta={options.ctaBannerLink}
                        />
                    )}

                    <div className="fixed bottom-0 left-0 flex items-center justify-between w-full p-6 pt-24 lg:hidden bg-gradient-to-t from-black to-black-0 z-[99]">
                        {options?.topCtaButton?.title && (
                            <NextNavLink
                                href={options?.topCtaButton?.url}
                                target={options?.topCtaButton?.target}
                            >
                                <a
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     setCTAOpen(true);
                                    // }}
                                    onClick={(e) => {
                                        setShowMenu(false);
                                    }}
                                    className="cursor-pointer btn btn--primary"
                                >
                                    {options?.topCtaButton?.title}
                                </a>
                            </NextNavLink>
                        )}
                        {options?.rightCtaButton?.title && (
                            <NextNavLink
                                href={options?.rightCtaButton?.url}
                                target={options?.rightCtaButton?.target}
                            >
                                <a
                                    onClick={(e) => {
                                        setShowMenu(false);
                                    }}
                                    className={`flex items-center lead opacity-80 hover:opacity-100 ${(sticky && !showMenu) ||
                                        (!variant && !showMenu)
                                        ? 'text-purple-midnight'
                                        : 'text-white'
                                        }`}
                                >
                                    {options?.rightCtaButton?.title}
                                </a>
                            </NextNavLink>
                        )}
                        <a href={'/clients/'}>

                            <li className={`group ${menuTextColor}  px-2 !mx-2  lg:!mx-0  custom-li list-none`}>

                                <button
                                    className="flex items-center lead hover:opacity-100"
                                    aria-expanded="false"
                                    style={{
                                        transition: 'color 0.4s ease-in-out',
                                    }}
                                >
                                    Log In
                                </button>

                            </li>
                        </a>
                        {/* {language.map((menuItem, index) => (
                            <Language
                                key={index}
                                menuItem={menuItem}
                                sticky={sticky && !showMenu}
                                bottom={true}
                            />
                        ))} */}
                    </div>
                </div>
            </Transition>
            <Transition
                show={CTAOpen}
                className="fixed top-0 left-0 z-[999] w-screen max-h-screen min-h-screen"
                enter="duration-200 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div className="w-screen max-h-screen min-h-screen bg-white bg-cover">
                    <div className="fixed right-6 top-7">
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setCTAOpen(!CTAOpen);
                            }}
                            className={`flex items-center lead opacity-80 hover:opacity-100 xl:text-purple-midnight text-white cursor-pointer`}
                        >
                            <span className="min-w-[42px]">Close</span>
                            <span
                                className={`icon-expaded  relative h-6 w-6 flex-col justify-between items-center flex ml-3`}
                            >
                                <span
                                    className={`absolute inline-block w-1 h-1 rounded-10 xl:bg-purple-midnight bg-white`}
                                />
                                <span
                                    className={`relative top-0 inline-block w-1 h-1 rounded-10 xl:bg-purple-midnight bg-white`}
                                />
                                <span
                                    className={`inline-block w-1 h-1 rounded-10 xl:bg-purple-midnight bg-white`}
                                />
                                <span
                                    className={`relative bottom-0 inline-block w-1 h-1 rounded-10 xl:bg-purple-midnight bg-white`}
                                />
                                <span
                                    className={`absolute bottom-0 inline-block w-1 h-1 rounded-10 xl:bg-purple-midnight bg-white`}
                                />
                            </span>
                        </a>
                    </div>
                    <iframe
                        loading="lazy"
                        sandbox="allow-downloads allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation-by-user-activation allow-same-origin"
                        src={options?.topCtaButton?.url}
                        data-src=""
                        width="100%"
                        height="100%"
                        type="text/html"
                        frameborder="0"
                        className="w-screen max-h-screen min-h-screen"
                        allowtransparency="true"
                    />
                </div>
            </Transition>
        </>
    );
}

// Desktop menu Bottom Baner
// Content can be changed from Option page
function Banner({ heading, cta }) {
    return (
        <div className="hidden py-6 bg-center bg-cover bg-gradient-3 lg:block">
            <div className="container flex flex-row items-center justify-between">
                <h5 className="text-white">{heading}</h5>
                {cta && (
                    <a
                        target={cta.target}
                        href={getAbsoluteUrl(cta.url)}
                        className="flex items-center font-medium text-white lead icon-circle"
                    >
                        {cta.title}
                        <span className="relative flex items-center h-4 ml-4">
                            <i className="inline-block en-long-arrow text-32 leading-18 relative z-[1]"></i>

                            <svg
                                width="32"
                                height="32"
                                className="absolute -right-3 -top-2 icon-circle__circle"
                            >
                                <circle
                                    cx="16"
                                    cy="16"
                                    r="15"
                                    className="icon-circle__base"
                                ></circle>
                                <circle
                                    cx="16"
                                    cy="16"
                                    r="15"
                                    className="icon-circle__fill"
                                ></circle>
                            </svg>
                        </span>
                    </a>
                )}
            </div>
        </div>
    );
}
export default Navigation;
