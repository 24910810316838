import React, { useEffect, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';

import NextNavLink from '../../../util/NextNavLink';
import { Dropdown, Language } from '../Dropdown';

import { useDebounce, useClickOutside } from '../../../../util/hooks';
import { getAbsoluteUrl } from '../../../../lib/helpers';

export const Navigation = ({
    menuItems,
    language,
    legalMenu,
    social,
    options,
    hideMenu
}) => {
    let date = new Date().getFullYear();
    return (
        <>
            <footer className="pt-12 bg-cover md:pt-20 bg-gradient-12">
                <div className="container items-end justify-between mt-3 sm:flex mb-14">
                    <img
                        src={options.footerLogo.mediaItemUrl}
                        alt="Enfusion"
                        className="w-[180px] h-auto"
                    />
                    <div className="flex mt-12 space-x-4 sm:mt-0">
                        {options.footerLink && (
                            <div className="lg:pr-5">
                                <a
                                    href={getAbsoluteUrl(
                                        options.footerLink?.url
                                    )}
                                    target={options.footerLink?.target}
                                    className="flex items-center font-medium text-white lead icon-circle"
                                >
                                    {options.footerLink?.title}
                                    <span className="relative h-4 ml-4">
                                        <i className="inline-block en-long-arrow text-32 leading-18 relative z-[1]"></i>

                                        <svg
                                            width="32"
                                            height="32"
                                            className="absolute -right-3 -top-2 icon-circle__circle"
                                        >
                                            <circle
                                                cx="16"
                                                cy="16"
                                                r="15"
                                                className="icon-circle__base"
                                            ></circle>
                                            <circle
                                                cx="16"
                                                cy="16"
                                                r="15"
                                                className="icon-circle__fill"
                                            ></circle>
                                        </svg>
                                    </span>
                                </a>
                            </div>
                        )}
                        {options.footerLink2 && (
                            <div className="lg:pr-5">
                                <a
                                    href={getAbsoluteUrl(
                                        options.footerLink2?.url
                                    )}
                                    target={options.footerLink2?.target}
                                    className="flex items-center font-medium text-white lead icon-circle"
                                >
                                    {options.footerLink2?.title}
                                    <span className="relative h-4 ml-3">
                                        <i className="inline-block en-long-arrow text-32 leading-18 relative z-[1]"></i>

                                        <svg
                                            width="32"
                                            height="32"
                                            className="absolute -right-3 -top-2 icon-circle__circle"
                                        >
                                            <circle
                                                cx="16"
                                                cy="16"
                                                r="15"
                                                className="icon-circle__base"
                                            ></circle>
                                            <circle
                                                cx="16"
                                                cy="16"
                                                r="15"
                                                className="icon-circle__fill"
                                            ></circle>
                                        </svg>
                                    </span>
                                </a>
                            </div>
                        )}
                    </div>
                </div>

                {!hideMenu && (
                    <div className="flex flex-col justify-between w-full">
                        <div className="container">
                            <div className="grid flex-row flex-wrap w-full gap-10 mb-10 md:gap-8 lg:justify-center sm:grid-cols-2 lg:grid-cols-4 lg:pb-0">
                                {menuItems &&
                                    menuItems.map((menuItem, index) => (
                                        <div
                                            className={`flex flex-col lg:flex-1 lg:max-w-[256px] w-full space-y-11 ${
                                                index === 3
                                                    ? 'lg:border-l lg:border-opacity-30 lg:border-white lg:pl-24'
                                                    : ''
                                            }`}
                                            key={index}
                                        >
                                            {menuItem.children.map(
                                                (childMenuItem, index) => (
                                                    <div
                                                        key={index}
                                                        className=""
                                                    >
                                                        <p className="mb-3 font-medium text-white label">
                                                            {
                                                                childMenuItem.title
                                                            }
                                                        </p>
                                                        <ul className="flex flex-col space-y-3 lead">
                                                            {childMenuItem.children.map(
                                                                (item, i) => (
                                                                    <li key={i}>
                                                                        <NextNavLink
                                                                            href={
                                                                                item.url ||
                                                                                item.path
                                                                            }
                                                                            target={
                                                                                item.target
                                                                            }
                                                                        >
                                                                            <a className="text-white opacity-70 hover:opacity-100">
                                                                                {
                                                                                    item.title
                                                                                }
                                                                            </a>
                                                                        </NextNavLink>
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                )
                                            )}

                                            {index === 0 && (
                                                <div className="flex flex-col justify-end lg:flex-1 lg:max-w-[256px] w-full space-y-11">
                                                    {options?.footerTagline ? (
                                                        <img
                                                            src={
                                                                options
                                                                    ?.footerTagline
                                                                    ?.mediaItemUrl
                                                            }
                                                            alt="Enfusion"
                                                            className="lg:w-[85%] w-4/5"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={
                                                                options
                                                                    ?.footerLogo
                                                                    ?.mediaItemUrl
                                                            }
                                                            alt="Enfusion"
                                                            className="w-[180px] h-auto"
                                                        />
                                                    )}
                                                </div>
                                            )}

                                            {index === 3 && (
                                                <div className="flex flex-row items-center pt-2 space-x-4 text-20 leading-20">
                                                    {social &&
                                                        social.map(
                                                            (item, index) => (
                                                                <a
                                                                    key={index}
                                                                    href={getAbsoluteUrl(
                                                                        item.link
                                                                    )}
                                                                    className="text-white opacity-70 hover:opacity-100"
                                                                    target="_blank"
                                                                >
                                                                    <i
                                                                        className={`en-${item.social}`}
                                                                    ></i>
                                                                </a>
                                                            )
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                )}

                <div className="container justify-between sm:flex md:pb-16 pb-9">
                    <div className="mb-10 sm:hidden">
                        {/* {language.map((menuItem, index) => (
                            <Language key={index} menuItem={menuItem} bottom />
                        ))} */}
                    </div>
                    <p className="mb-4 text-white opacity-70 lead lead--small sm:mb-0">
                        &copy; {date} {options.footerCopyright}
                    </p>
                    <div className="flex gap-4 lg:pr-10">
                        {legalMenu &&
                            legalMenu.map((item, index) => (
                                <NextNavLink
                                    href={item.url}
                                    key={index}
                                    target={item.target}
                                >
                                    <a className="text-white opacity-70 hover:opacity-100 lead lead--small">
                                        {item.title}
                                    </a>
                                </NextNavLink>
                            ))}
                    </div>
                </div>
            </footer>
        </>
    );
};

export const EventNavigation = ({
    legalMenu,
    social,
    options,
    text = 'Join us to receive the latest trends, reports, and product updates.'
}) => {
    let date = new Date().getFullYear();
    return (
        <>
            <footer className="pt-12 bg-cover md:pt-20 bg-gradient-12">
                <div className="container items-end justify-between mt-3 mb-16 sm:flex">
                    <img src={options.footerLogo.mediaItemUrl} alt="Enfusion" />
                </div>
                <div className="flex flex-col justify-between w-full">
                    <div className="container">
                        <div className="block mx-auto mb-10 sm:px-4 sm:max-w-sm lg:mb-20">
                            <div
                                className="mb-10 text-white h4"
                                dangerouslySetInnerHTML={{ __html: text }}
                            />
                            <iframe
                                height="100%"
                                width="100%"
                                allowtransparency="true"
                                type="text/html"
                                sandbox="allow-downloads allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation-by-user-activation allow-same-origin"
                                src="https://welcome.enfusion.com/l/833443/2021-06-22/grsqk"
                                style={{ height: `120px` }}
                            ></iframe>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-center mb-24 space-x-12 sm:hidden text-20 leading-20">
                    {social &&
                        social.map((item, index) => (
                            <a
                                key={index}
                                href={getAbsoluteUrl(item.link)}
                                className="text-white opacity-70 hover:opacity-100"
                                target="_blank"
                            >
                                <i className={`en-${item.social}`}></i>
                            </a>
                        ))}
                </div>

                <div className="container items-center justify-between space-y-4 sm:flex md:pb-16 pb-9 md:space-y-0 ">
                    <div className="mb-10 sm:hidden">
                        {/* {language.map((menuItem, index) => (
                            <Language key={index} menuItem={menuItem} bottom />
                        ))} */}
                    </div>
                    <p className="mb-4 text-white opacity-70 lead lead--small sm:mb-0">
                        &copy; {date} {options.footerCopyright}
                    </p>
                    <div className="flex-row items-center hidden space-x-12 sm:flex text-20 leading-20">
                        {social &&
                            social.map((item, index) => (
                                <a
                                    key={index}
                                    href={getAbsoluteUrl(item.link)}
                                    className="text-white opacity-70 hover:opacity-100"
                                    target="_blank"
                                >
                                    <i className={`en-${item.social}`}></i>
                                </a>
                            ))}
                    </div>
                    <div className="flex gap-4 lg:pr-10">
                        {legalMenu &&
                            legalMenu.map((item, index) => (
                                <NextNavLink
                                    href={item.url}
                                    key={index}
                                    target={item.target}
                                >
                                    <a className="text-white opacity-70 hover:opacity-100 lead lead--small">
                                        {item.title}
                                    </a>
                                </NextNavLink>
                            ))}
                    </div>
                </div>
            </footer>
        </>
    );
};
